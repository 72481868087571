import React, { useState, createContext } from 'react';

export type Taxonomy = { codename: string; name: string };

export interface PageRelevancyCategory {
    elements: {
        associated_taxonomy_code_name: {
            value: string;
        };
    };
}

interface Props extends TaxonomyObject {
    blog_topic?: {
        value: Taxonomy[];
    };

    wellness_type?: {
        value: Taxonomy[];
    };

    for_page_relevancy?: {
        value: PageRelevancyCategory[];
    };
}

export interface TaxonomyObject {
    wellnessTypes?: Taxonomy[];
    blogTopics?: Taxonomy[];
    for_page_relevancy?: {
        value: PageRelevancyCategory[];
    };
}

export const TaxonomyContext = createContext<TaxonomyObject>({
    wellnessTypes: [],
    blogTopics: [],
});

const TaxonomyProvider: React.FC<Props> = ({
    blog_topic,
    wellness_type,
    for_page_relevancy,
    ...props
}) => {
    let wellnessTypes = wellness_type?.value;
    let blogTopics = blog_topic?.value;

    const [taxonomy, setTaxonomy] = useState<TaxonomyObject>({
        wellnessTypes: wellnessTypes,
        blogTopics: blogTopics,
        for_page_relevancy,
    });

    return (
        <TaxonomyContext.Provider value={taxonomy}>
            {props.children}
        </TaxonomyContext.Provider>
    );
};

export default TaxonomyProvider;
