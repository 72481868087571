import React from 'react';
import { graphql } from 'gatsby';
//@ts-ignore
import loadable from '@loadable/component';

const NavigationContainer = loadable(() => import('./Navigation/index'));
const Footer = loadable(() => import('./Footer'));
const AlertBanner = loadable(() => import('./AlertBanner'));
const SocialShare = loadable(() => import('./SocialShare'));
const PageScrollIndicator = loadable(() => import('./PageScrollIndicator'));
const MobileUtilityBar = loadable(() => import('./MobileUtilityBar'));

const Components = {
    component___navigation: NavigationContainer,
    component___footer: Footer,
    component___alert_banner: AlertBanner,
    component___social_share: SocialShare,
    component___mobile_utility_bar: MobileUtilityBar,
    component___page_scroll_indicator: PageScrollIndicator,
};

const NavigationComponent = ({ name, ...props }: { name: string }) => {
    //@ts-ignore
    let Component = Components[name];

    if (!Component) {
        return null;
    }

    return <Component {...props} />;
};

export default NavigationComponent;
