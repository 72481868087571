import {
    KontentMedia,
    BrandColor,
    KontentRadioMultiChoice,
    KontentIcon,
    Statement,
    CTAConfiguration,
    CTALocation,
    KontentStringValue,
} from '../../../../types/KontentProps';
import { BuildLinkHref } from '../BuildLinkHref';
import { BlogPagePreview } from './blogQueries';

export interface MediaData {
    name: string;
    description: string;
    url: string;
}

export function getMediaData(data: KontentMedia | undefined): MediaData | null {
    if (
        !data?.value?.length ||
        !data?.value[0]?.elements?.file?.value[0]?.url
    ) {
        return null;
    }

    return {
        name: data?.value[0]?.elements?.file?.value[0]?.name,
        description: data?.value[0]?.elements?.file?.value[0]?.description,
        url: data?.value[0]?.elements?.file?.value[0]?.url,
    };
}

export function getColor(data: BrandColor | undefined): string | null {
    if (
        !data?.value?.length ||
        !data?.value[0]?.elements?.color_hex_value?.value
    ) {
        return '';
    }

    return `#${data.value[0]?.elements.color_hex_value.value}`;
}

export function getMultiChoice(data: KontentRadioMultiChoice<any> | undefined) {
    return data?.value[0]?.codename || null;
}

export function getIcon(data: KontentIcon | undefined) {
    return {
        url: data?.value[0]?.elements?.icon_svg_file?.value[0]?.url,
        description:
            data?.value[0]?.elements?.icon_svg_file?.value[0]?.description,
        name: data?.value[0]?.elements?.wellness_type?.value?.name,
    };
}

export function getStatement(statement: Statement) {
    return {
        displayTitle: statement.elements?.display_title,
        richDescription: statement.elements?.rich_description,
        icon: getIcon(statement.elements?.icon),
    };
}

export function getTimeToRead(page: BlogPagePreview) {
    const combinedMinutesToRead = page.elements.page_components.value.map(
        (component) => {
            if (component.system?.type === 'component___blog_content') {
                return component.readingTime.minutes;
            }

            return 0;
        }
    );

    const sumMinutesToRead = combinedMinutesToRead.reduce((a, b) => a + b, 0);

    return Math.ceil(sumMinutesToRead);
}
