// passes all the data to the respected components

import React from 'react';
import { graphql } from 'gatsby';
//@ts-ignore
import loadable from '@loadable/component';
const GlobalMasthead = loadable(() => import('./GlobalMasthead'));
const ImageWithCta = loadable(() => import('./ImageWithCta'));
const BlogScrollContainer = loadable(() => import('./BlogScroll/index'));
const ImageWithStatementBlock = loadable(
    () => import('./ImageWithStatementBlock')
);
const ProductDetailMastheadContainer = loadable(
    () => import('./ProductDetailMasthead')
);
const StatementBlockWithIconOption = loadable(
    () => import('./StatementBlockWithIconOption')
);
const SupportContactPanel = loadable(() => import('./SupportContactPanel'));
const SupportMasthead = loadable(() => import('./SupportMasthead'));
const WhatSaunaContainer = loadable(
    () => import('./WhatSaunaIsRightForYouForm')
);
const TileCarouselContainer = loadable(() => import('./TileCarouselContainer'));
const QuotedText = loadable(() => import('./QuotedText'));
const Headline = loadable(() => import('./Headline'));
const PageRelevancyTagsContainer = loadable(
    () => import('./PageRelevancyTags')
);
const ColumnizedContent = loadable(() => import('./ColumnizedContent'));
const GlobalOffices = loadable(() => import('./GlobalOffices'));
const ProductSpecifications = loadable(() => import('./ProductSpecifications'));
const TopicCallOut = loadable(() => import('./TopicCallOut'));
const ContactUsOverlay = loadable(() => import('./ContactUsOverlay'));
const ContactUsPanel = loadable(() => import('./ContactUsPanel'));
const BlogAuthorPanel = loadable(() => import('./BlogAuthorPanel'));
const RteComponent = loadable(() => import('./RteComponent'));
const ShopPanelWithInsetImage = loadable(
    () => import('./ShopPanelWithInsetImage')
);
const PromotionalDiscountOverlay = loadable(
    () => import('./PromotionalDiscountOverlay')
);
const ProductVariants = loadable(() => import('./ProductVariants'));
const BlogBreadcrumbsContainer = loadable(
    () => import('./BlogBreadcrumbs/index')
);
const JumpToTopic = loadable(() => import('./JumpToTopic'));
const BlogDetailMastheadContainer = loadable(
    () => import('./BlogDetailMasthead')
);
const ImageGallery = loadable(() => import('./ImageGallery'));
const HighlightedContentBlock = loadable(
    () => import('./HighlightedContentBlock')
);
const BlogContentRendering = loadable(() => import('./BlogContentRendering'));
const PartnerTestimonials = loadable(() => import('./TestimonialScroll'));
const ImageCopyBlock = loadable(() => import('./ImageCopyBlock'));
const DiscountBanner = loadable(() => import('./DiscountBanner'));
const GeneralJotform = loadable(() => import('./GeneralJotForm'));
const CtaBar = loadable(() => import('./CtaBar'));
const ColorPanel = loadable(() => import('./ColorPanel'));
const NumberedPanel = loadable(() => import('./Numbered Panel'));
const CountrySelectOverlay = loadable(() => import('./CountrySelectOverlay'));
const PromotionalStyleA = loadable(() => import('./PromotionalstylesA'));
const FilteredContentContainer = loadable(() => import('./FilteredContent'));
const PromotedContentStyleB = loadable(() => import('./PromotedContentStyleB'));
const ProductDescription = loadable(() => import('./ProductDescription'));
const GuidesAndManualsContainer = loadable(() => import('./GuidesAndManuals'));
const ProductSupportLayoutContainer = loadable(
    () => import('./ProductSupportLayout')
);
// const GeneralTypeform = loadable(() => import('./GeneralTypeform'));
const ProductComparison = loadable(() => import('./ProductComparison'));
const ProductFamilyCarouselContainer = loadable(
    () => import('./ProductFamilyCarousel/index')
);
const ProductSideBySideContainer = loadable(
    () => import('./ProductSideBySide')
);

const ProductDetailHighlight = loadable(
    () => import('./ProductDetailHighlight/ProductDetailHighlight')
);
const TrustPilot = loadable(() => import('./TrustPilot'));
const BlogReferences = loadable(() => import('./BlogReferences'));
const RelatedProductsContainer = loadable(
    () => import('./RelatedProducts/index')
);
const SupportSearchResultsContainer = loadable(
    () => import('./SupportSearchResults')
);
const OnPageSupportSearchContainer = loadable(
    () => import('./OnPageSupportSearch')
);
const OneTrustCookieButton = loadable(() => import('./OneTrustCookieButton'));
const OneTrustCookieList = loadable(() => import('./OneTrustCookieList'));

const Components = {
    component___global_masthead: GlobalMasthead,
    component___statement_block_with_icon_option: StatementBlockWithIconOption,
    component___headline: Headline,
    component___page_relevancy_tags: PageRelevancyTagsContainer,
    component___quoted_text: QuotedText,
    component___topic_call_outs: TopicCallOut,
    component___numbered_panels: NumberedPanel,
    component___product_detail_masthead: ProductDetailMastheadContainer,
    component___product_detail_highlight: ProductDetailHighlight,
    component___columnized_content: ColumnizedContent,
    component___blog_scroll: BlogScrollContainer,
    component___product_variants: ProductVariants,
    component___blog_breadcrumb: BlogBreadcrumbsContainer,
    component___jump_to_topic: JumpToTopic,
    component___blog_masthead: BlogDetailMastheadContainer,
    component___half_image_copy_block_a9f8fc6: ImageCopyBlock,
    component___promotional_style_a: PromotionalStyleA,
    component___global_maps_and_cards: GlobalOffices,
    component___image_with_cta: ImageWithCta,
    component___product_specifications: ProductSpecifications,
    component___tile_carousel__container_: TileCarouselContainer,
    component___what_sauna_is_right_for_you: WhatSaunaContainer,
    component___contact_overlay: ContactUsOverlay,
    component___contact_panel: ContactUsPanel,
    component___author: BlogAuthorPanel,
    component___rte: RteComponent,
    component___promotion_discount_overlay: PromotionalDiscountOverlay,
    component___highlight_content_block: HighlightedContentBlock,
    component___blog_content: BlogContentRendering,
    component___partner_testimonials: PartnerTestimonials,
    component___discount_banner: DiscountBanner,
    component___cta_bar: CtaBar,
    component___color_panel: ColorPanel,
    component___basic_image_gallery: ImageGallery,
    component___country_select_overlay: CountrySelectOverlay,
    component___product_family_carousel: ProductFamilyCarouselContainer,
    component___shop_panel_with_inset_image: ShopPanelWithInsetImage,
    component___filtered_content: FilteredContentContainer,
    component___promotional_style_b: PromotedContentStyleB,
    component___product_description: ProductDescription,
    component___guides_and_manuals: GuidesAndManualsContainer,
    component___product_support_layout: ProductSupportLayoutContainer,
    component___form_container: GeneralJotform,
    component___trust_pilot_reviews: TrustPilot,
    component___blog_references: BlogReferences,
    component___product_comparison: ProductComparison,
    component___related_products: RelatedProductsContainer,
    component___support_contact_panel: SupportContactPanel,
    component___image_with_statement_block: ImageWithStatementBlock,
    component___support_masthead: SupportMasthead,
    component___support_search: SupportSearchResultsContainer,
    component___on_page_support_search: OnPageSupportSearchContainer,
    component___product_side_by_side: ProductSideBySideContainer,
    component___onetrust_cookie_button: OneTrustCookieButton,
    component___onetrust_cookie_list: OneTrustCookieList,
};

const ReferenceComponent = ({ name, ...props }: { name: string }) => {
    //@ts-ignore
    let Component = Components[name];
    if (!Component) {
        return null;
    }

    return <Component {...props} />;
};

export default ReferenceComponent;
