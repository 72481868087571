//template component or a main page which does all the initialization sets up the navigation and passes the graphql data to reference component which inturn circulates it to the respective component
import 'proxy-polyfill'; // used by framer-motion, IE11 will silently fail to display/load components without proxy-polyfill
import React, { useState, createContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../global/layout';
import { graphql, PageProps } from 'gatsby';
import ReferenceComponent from '../components/ReferenceComponent';
import {
    KontentMedia,
    KontentRadioMultiChoice,
    KontentStringValue,
} from '../../types/KontentProps';
import TaxonomyProvider from './TaxonomyContext';
import NavigationComponent from '../components/NavigationComponent';
import { getColor, getMultiChoice } from '../components/Global/DataUtils';

interface Props {
    readonly data: PageQueryData;
    readonly pageContext: {
        slug: string;
        paths: { [key: string]: string };
        languages: string[];
    };
    readonly location: PageProps['location'];
}

export const PageMetadataContext = createContext({
    slug: undefined as string | undefined,
    blogTitle: undefined as string | undefined,
    blogShortDescription: undefined as string | undefined,
    displayPageName: undefined as string | undefined,
    preferredLanguage: undefined as string | undefined,
    location: undefined as string | PageProps['location'],
    pagePaths: {} as { [key: string]: string },
});

const PageTemplate: React.FC<Props> = ({
    data,
    pageContext,
    location,
    ...props
}) => {
    const elements = data?.kontentItemLayoutMasterPage?.elements;
    const blogMetadata =
        elements.other_metadata?.value[0]?.elements || undefined;
    const blogPublicationString = blogMetadata
        ? elements.page_components?.value?.filter(
              (component) =>
                  component.__typename ===
                  'kontent_item_component___blog_masthead'
          )[0]?.elements?.display_publish_date?.value
        : null;
    const blogPublicationDate =
        blogPublicationString && new Date(blogPublicationString)?.toISOString();

    const blogTitle = blogMetadata ? blogMetadata?.title?.value : '';
    const blogShortDescription = blogMetadata
        ? blogMetadata?.short_description?.value
        : '';
    const displayPageName = elements.display_page_name?.value || '';
    const pageBackgroundColor =
        getColor(elements.page_background_color) || undefined;
    const preferredLanguage =
        data?.kontentItemLayoutMasterPage?.preferred_language;
    const [pageMetadata, setPageMetadata] = useState({
        slug: elements.slug.value,
        blogTitle: blogTitle,
        blogShortDescription: blogShortDescription,
        displayPageName: displayPageName,
        preferredLanguage: preferredLanguage,
        location: location,
        pagePaths: pageContext.paths,
    });

    const pageTitle = `${
        elements?.seo_page_title?.value ||
        blogTitle ||
        elements?.display_page_name?.value
    } | Sunlighten`;

    const slug =
        elements?.slug.value === 'home'
            ? ''
            : pageContext.paths[elements?.slug.value];
    const locationRoute =
        preferredLanguage === 'default'
            ? ''
            : '/' + preferredLanguage.toLowerCase();
    const pageUrl = 'https://www.sunlighten.com' + locationRoute + slug + '/';

    const searchDescription = elements?.page_search_summary?.value;
    const searchImage =
        elements?.page_search_image?.value[0]?.elements.file?.value[0];
    const searchImageUrl = searchImage?.url;
    const searchImageDescription = searchImage?.description;
    const searchImageWidth = searchImage?.width;
    const searchImageHeight = searchImage?.height;
    const indexChoice =
        getMultiChoice(elements.exclude_from_internal_indexing) === 'yes'
            ? 'noindex'
            : 'index';
    const noFollowChoice =
        getMultiChoice(elements.no_follow) === 'yes' ? 'nofollow' : 'follow';
    const isoCode = getIsoCode(preferredLanguage);
    const schemaBaseUrl =
        'https://www.sunlighten.com' +
        (locationRoute === '/' ? locationRoute : locationRoute + '/');
    const lastModified =
        data?.kontentItemLayoutMasterPage?.system?.last_modified;
    const schemaImageObject = searchImageUrl
        ? `{
                "@type": "ImageObject",
                "@id": "${pageUrl}#primaryimage",
                "inLanguage": "${isoCode}",
                "url": "${searchImageUrl}",
                "width": ${searchImageWidth},
                "height": ${searchImageHeight},
                "caption": "${searchImageDescription || 'Sunlighten Saunas'}"
            },`
        : '';
    const schemaWebpageObject =
        elements.page_type?.value[0]?.codename !== 'blog'
            ? `{
                "@type": "WebPage",
                "@id": "${pageUrl}#webpage",
                "url": "${pageUrl}",
                "name": "${pageTitle}",
                "isPartOf": { "@id": "${schemaBaseUrl}#website" },
                "primaryImageOfPage": {
                    "@id": "${pageUrl}#primaryimage"
                },
                "dateModified": "${lastModified}",
                "description": "${searchDescription}",
                "inLanguage": "${isoCode}",
                "potentialAction": [
                    {
                        "@type": "ReadAction",
                        "target": [
                            "${pageUrl}"
                        ]
                    }
                ]
            }`
            : `{
                "@type": "WebPage",
                "@id": "${pageUrl}#webpage",
                "url": "${pageUrl}",
                "name": "${pageTitle}",
                "isPartOf": { "@id": "${schemaBaseUrl}#website" },
                "primaryImageOfPage": {
                    "@id": "${pageUrl}#primaryimage"
                },
                "datePublished": "${blogPublicationDate}",
                "dateModified": "${lastModified}",
                "description": "${searchDescription}",
                "inLanguage": "${isoCode}",
                "potentialAction": [
                    {
                        "@type": "ReadAction",
                        "target": [
                            "${pageUrl}"
                        ]
                    }
                ]
            },
            {
                "@type": "Article",
                "@id": "${pageUrl}#article",
                "isPartOf": {
                    "@id": "${pageUrl}#webpage"
                },
                "author": {
                    "@id": "https://www.sunlighten.com/#/schema/person/3c124f1e3bb6c6e47361f4a996e634cb"
                },
                "headline": "${blogTitle}",
                "datePublished": "${blogPublicationDate}",
                "dateModified": "${lastModified}",
                "mainEntityOfPage": {
                    "@id": "${pageUrl}/#webpage"
                },
                "publisher": {
                    "@id": "${schemaBaseUrl}#organization"
                },
                "image": {
                    "@id": "${pageUrl}#primaryimage"
                },
                "articleSection": "Health &amp; Wellness",
                "inLanguage": "${isoCode}"
		},
		{
			"@type": "Person",
			"@id": "https://www.sunlighten.com/#/schema/person/3c124f1e3bb6c6e47361f4a996e634cb",
			"name": "Sunlighten, Inc.",
			"image": {
				"@type": "ImageObject",
				"@id": "https://www.sunlighten.com/#personlogo",
				"inLanguage": "${isoCode}",
				"url": "https://secure.gravatar.com/avatar/?s=96&d=blank&r=g",
				"caption": "Sunlighten, Inc."
			},
			"description": "${blogShortDescription}"
		}`;

    const renderHreflangLinks = () => {
        return pageContext.languages.map((codename, idx) => {
            const languageCode = getIsoCode(codename);

            if (languageCode === 'en-US') {
                return;
            }

            return (
                <link
                    key={'hreflang' + idx}
                    rel="alternate"
                    hrefLang={languageCode}
                    href={
                        'https://www.sunlighten.com/' +
                        codename.toLowerCase() +
                        slug
                    }
                />
            );
        });
    };
    return (
        <TaxonomyProvider
            wellness_type={elements.wellness_type}
            blog_topic={elements.blog_topic}
            for_page_relevancy={elements.for_page_relevancy}
        >
            <PageMetadataContext.Provider value={pageMetadata}>
                <Layout>
                    <div style={{ backgroundColor: pageBackgroundColor }}>
                        <Helmet>
                            <title>{pageTitle}</title>
                            <meta name="title" content={pageTitle} />
                            <meta
                                name="description"
                                content={searchDescription}
                            />
                            <meta
                                name="robots"
                                content={`${indexChoice}, ${noFollowChoice}`}
                            />

                            <meta property="og:type" content="website" />
                            <meta property="og:url" content={pageUrl} />
                            <meta property="og:title" content={pageTitle} />
                            <meta
                                property="og:description"
                                content={searchDescription}
                            />
                            {searchImageUrl && (
                                <meta
                                    property="og:image"
                                    content={searchImageUrl}
                                />
                            )}
                            <meta
                                property="twitter:card"
                                content="summary_large_image"
                            />
                            <meta property="twitter:url" content={pageUrl} />
                            <meta
                                property="twitter:title"
                                content={pageTitle}
                            />
                            <meta
                                property="twitter:description"
                                content={searchDescription}
                            />
                            {searchImageUrl && (
                                <meta
                                    property="twitter:image"
                                    content={searchImageUrl}
                                />
                            )}
                            <link rel="canonical" href={pageUrl} />
                            <link
                                rel="alternate"
                                hrefLang="en-US"
                                href={'https://www.sunlighten.com' + slug}
                            />
                            {renderHreflangLinks()}
                            <link
                                rel="alternate"
                                hrefLang="x-default"
                                href={'https://www.sunlighten.com' + slug}
                            />

                            <script type="application/ld+json">
                                {`
                                    {
                                        "@context": "https://schema.org",
                                        "@graph": [
                                            {
                                                "@type": "Organization",
                                                "@id": "${schemaBaseUrl}#organization",
                                                "name": "Sunlighten Saunas",
                                                "url": "${schemaBaseUrl}",
                                                "sameAs": [
                                                    "https://www.facebook.com/SunlightenSaunas/",
                                                    "https://www.instagram.com/sunlightensaunas/",
                                                    "https://www.linkedin.com/company/sunlighten/",
                                                    "https://www.youtube.com/channel/UCznjuWOuQK_uP5CYYJew7WQ",
                                                    "https://twitter.com/sunlighten"
                                                ],
                                                "logo": {
                                                    "@type": "ImageObject",
                                                    "@id": "${schemaBaseUrl}#logo",
                                                    "inLanguage": "${isoCode}",
                                                    "url": "https://preview-assets-us-01.kc-usercontent.com/9832d3f0-685b-0021-9fc3-0a88ee8dfc7a/73191d70-5cb0-44d8-80f6-8b52cdc8415b/SunlightenLogo_4C_182x87.svg",
                                                    "width": 182,
                                                    "height": 87,
                                                    "caption": "Sunlighten Saunas"
                                                },
                                                "image": { "@id": "${schemaBaseUrl}#logo" }
                                            },
                                            {
                                                "@type": "WebSite",
                                                "@id": "${schemaBaseUrl}#website",
                                                "url": "${schemaBaseUrl}",
                                                "name": "Sunlighten",
                                                "description": "",
                                                "publisher": {
                                                    "@id": "${schemaBaseUrl}#organization"
                                                },
                                                "inLanguage": "${isoCode}"
                                            },
                                            ${schemaImageObject}
                                            ${schemaWebpageObject}
                                        ],}
                                `}
                            </script>
                            <script
                                async
                                type="module"
                                src="https://unpkg.com/%40epigraph/ar/dist/epigraph-ar.min.js"
                            ></script>
                        </Helmet>
                        <div className="wrapper">
                            {elements?.navigation_elements?.value?.map(
                                (component, idx) => {
                                    if (
                                        component.system.type ===
                                        'component___footer'
                                    )
                                        return null;
                                    return (
                                        <NavigationComponent
                                            name={component.system.type}
                                            key={`${component.__typename}${idx}`}
                                            {...component?.elements}
                                        />
                                    );
                                }
                            )}
                            {elements?.page_components?.value?.map(
                                (component, idx) => (
                                    <ReferenceComponent
                                        name={component.system.type}
                                        key={`${component.__typename}${idx}`}
                                        {...component?.elements}
                                    />
                                )
                            )}
                            {elements?.navigation_elements?.value?.map(
                                (component, idx) => {
                                    if (
                                        component.system.type !==
                                        'component___footer'
                                    )
                                        return null;
                                    return (
                                        <NavigationComponent
                                            name={component.system.type}
                                            key={`${component.__typename}${idx}`}
                                            {...component?.elements}
                                        />
                                    );
                                }
                            )}
                        </div>
                    </div>
                </Layout>
            </PageMetadataContext.Provider>
        </TaxonomyProvider>
    );
};

function getIsoCode(languageCodename: string) {
    if (languageCodename === 'default') {
        return 'en-US';
    }

    const lowercaseCodename = languageCodename.toLowerCase();
    const splitCodename = lowercaseCodename.split('-');
    const language = splitCodename[splitCodename.length - 1];
    const location = splitCodename[0];

    return language + '-' + location.toUpperCase();
}

export default PageTemplate;
interface PageQueryData {
    kontentItemLayoutMasterPage: {
        preferred_language: string;
        system: {
            last_modified: string;
        };
        elements: {
            display_page_name: KontentStringValue;
            exclude_from_internal_indexing: KontentRadioMultiChoice<
                'yes' | 'no'
            >;
            no_follow: KontentRadioMultiChoice<'yes' | 'no'>;
            page_background_color: {
                value: {
                    elements: {
                        color_hex_value: {
                            value: string;
                        };
                    };
                }[];
            };
            page_type: KontentRadioMultiChoice<
                'blog' | 'product' | 'support' | 'case_study' | 'careers'
            >;
            seo_page_title: KontentStringValue;
            page_search_image: KontentMedia;
            page_search_summary: {
                value: string;
            };
            slug: {
                value: string;
            };
            navigation_elements: {
                value: {
                    system: {
                        type: string;
                    };
                    __typename: string;
                    id: string;
                    elements: {};
                }[];
            };
            page_components: {
                value: {
                    system: {
                        type: string;
                    };
                    __typename: string;
                    id: string;
                    elements: {};
                }[];
            };
            wellness_type?: {
                value: {
                    codename: string;
                    name: string;
                }[];
            };
            blog_topic?: {
                value: {
                    codename: string;
                    name: string;
                }[];
            };
            for_page_relevancy?: {
                value: {
                    elements: {
                        associated_taxonomy_code_name: {
                            value: string;
                        };
                    };
                }[];
            };
            other_metadata?: {
                value: {
                    elements: {
                        title?: KontentStringValue;
                        short_description?: KontentStringValue;
                    };
                }[];
            };
        };
    };
}

export const pageQuery = graphql`
    query PageById($id: String!) {
        kontentItemLayoutMasterPage(id: { eq: $id }) {
            preferred_language
            system {
                last_modified
            }
            elements {
                display_page_name {
                    value
                }
                slug {
                    value
                }
                seo_page_title {
                    value
                }
                page_search_summary {
                    value
                }
                page_search_image {
                    value {
                        ... on kontent_item_media___search_image {
                            id
                            elements {
                                file {
                                    value {
                                        url
                                        description
                                        height
                                        width
                                    }
                                }
                            }
                        }
                    }
                }
                exclude_from_internal_indexing {
                    value {
                        codename
                    }
                }
                no_follow {
                    value {
                        codename
                    }
                }
                page_background_color {
                    ...kontentColor
                }
                page_type {
                    value {
                        codename
                    }
                }
                navigation_elements {
                    value {
                        ...Navigation
                        ...Footer
                        ...MobileUtilityBar
                        ...AlertBanner
                        ...SocialShare
                        ...PageScrollIndicator
                        ...MobileUtilityBar
                        system {
                            type
                        }
                    }
                }
                page_components {
                    value {
                        ...GlobalMasthead
                        ...StatementBlockWithIconOption
                        ...PageRelevancyTags
                        ...TileCarouselContainer
                        ...WhatSaunaIsRightForYouForm
                        ...QuotedText
                        ...ColumnizedContent
                        ...GlobalOffices
                        ...BlogScroll
                        ...ImageWithCta
                        ...ContactUsOverlay
                        ...Headline
                        ...ContactUsPanel
                        ...BlogAuthorPanel
                        ...RteComponent
                        ...ShopPanelWithInsetImage
                        ...PromotionalDiscountOverlay
                        ...BlogBreadcrumb
                        ...ProductVariants
                        ...JumpToTopic
                        ...BlogDetailMasthead
                        ...ProductDetailMasthead
                        ...ProductSpecifications
                        ...HighlightContentBlock
                        ...BlogContent
                        ...PartnerTestimonials
                        ...ImageCopyBlock
                        ...DiscountBanner
                        ...GeneralJotform
                        ...CtaBar
                        ...ColorPanel
                        ...NumberedPanel
                        ...CountrySelectOverlay
                        ...PromotionalStyleA
                        ...FilteredContent
                        ...PromotedContentStyleB
                        ...ProductDescription
                        ...GuidesAndManuals
                        ...ImageGallery
                        ...ProductSupportLayout
                        ...ProductComparison
                        ...ProductDetailHighlight
                        ...TrustPilot
                        ...BlogReferences
                        ...ProductFamilyCarousel
                        ...RelatedProducts
                        ...SupportContactPanel
                        ...ImageWithStatementBlock
                        ...TopicCallOut
                        ...SupportMasthead
                        ...SupportSearchResults
                        ...OnPageSupportSearch
                        ...ProductSideBySide
                        ...OneTrustCookieButton
                        ...OneTrustCookieList
                        system {
                            type
                        }
                    }
                }
                wellness_type {
                    value {
                        codename
                        name
                    }
                }
                blog_topic {
                    value {
                        codename
                        name
                    }
                }
                for_page_relevancy {
                    value {
                        ... on kontent_item_taxonomy_category {
                            id
                            elements {
                                associated_taxonomy_code_name {
                                    value
                                }
                            }
                        }
                    }
                }
                other_metadata {
                    value {
                        ... on kontent_item_data___blog_post {
                            elements {
                                title {
                                    value
                                }
                                short_description {
                                    value
                                }
                            }
                        }
                    }
                }
            }
            ...kontentMasterPageElements
        }
    }
`;
