import React from 'react';
import { PageProps } from 'gatsby';
import Container from './container';
import Navigation from '../components/demo/navigation';

// require() b/c typescript compiler ignores css import statement
// if import not assigned in component
require('./base.css');

/*
interface Props extends Omit<PageProps, 'children'> {
    children?: React.ReactNode | readonly React.ReactNode[]
}
*/

interface Props {
    children?: React.ReactNode | readonly React.ReactNode[];
}

const Layout: React.FC<Props> = ({ children }) => {
    return <Container>{children}</Container>;
};

export default Layout;
